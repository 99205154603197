import React, { useState, useEffect } from "react";
import { providerOptions } from "./Components/providerOptions";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import * as bt from "react-bootstrap";
import HighTableVault from "./ABI/HighTableVault.json";
import USDC from "./ABI/USDC.json";
import HighTableVaultETH from "./ABI/HighTableVaultETH.json";

import {
  ParseUnit,
  FormatUnit,
  ConvertCloseFund,
  ConvertDisableDP,
  ConvertDisableWT,
  ConvertDisableCancelDP,
  ConvertDisableCancelWT,
} from "./Components/etherMethod";

// const contractAddress = process.env.REACT_APP_HIGHTABLE_ADDRESS; // HighTableVault address
const etherScanPrefix = process.env.REACT_APP_ETHERSCAN;
const opContractAddress = process.env.REACT_APP_OP_ADDRESS;
const opPlusContractAddress = process.env.REACT_APP_OP_PLUS_ADDRESS;
const ethStakingContractAddress = process.env.REACT_APP_ETH_STAKING_ADDRESS;

const infuraURL = process.env.REACT_APP_INFURA_URL;
const ChainId = process.env.REACT_APP_CHAINID;

// creat web3Modal instance for connect wallet
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});

function App() {
  const [web3ModalProvider, setWeb3ModalProvider] = useState();
  const [ethersProvider, setEthersProvider] = useState();
  const [account, setAccount] = useState();
  const [contractSigner, setContractSigner] = useState();
  const [USDCProvider, setUSDCProvider] = useState();
  const [USDCName, setUSDCName] = useState();
  const [USDCDecimals, setUSDCDecimals] = useState();
  const [fundLockTimestamp, setFundLockTimestamp] = useState();
  const [signer, setSigner] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [currentSharePrice, setCurrentSharePrice] = useState();
  const [nextSharePrice, setNextSharePrice] = useState();
  const [currentAPR, setCurrentAPR] = useState();
  const [symbol, setSymbol] = useState();
  const [nextAPR, setNextAPR] = useState();
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [networkName, setNetworkName] = useState();
  const [contractAddress, setContractAddress] = useState();
  const [contractName, setContractName] = useState();
  const [teaVaultV2Address, setTeaVaultV2Address] = useState();
  const [isEthStaking, setIsEthStaking] = useState(false);
  const [teavaultLink, setTeavaultLink] = useState();
  const [cycleIndex, setCycleIndex] = useState(); // global state of HT : current cycleIndex
  const [depositLimit, setDepositLimit] = useState(); // current deposit limit
  const [lockAssets, setLockAsset] = useState(); // current lock assets
  const [fundClosed, setFundClosed] = useState(); // current fund closed state
  const [cycleStartTimestamp, setCycleStartTimestamp] = useState(); // start timestamp
  const [fundingLockTimestamp, setFundingLockTimestamp] = useState(); // lock timestamp
  const [newFundingLockTimestamp, setNewFundingLockTimestamp] = useState(); // set new fundingLock
  const [newFundingLockLocalTime, setNewFundingLockLocalTime] = useState();
  const [newDepositLimit, setNewDepositLimit] = useState(); // set new deposit limit
  const [newDepositLimitBig, setNewDepositLimitBig] = useState();
  const [txLink, setTxLink] = useState(); // transaction on etherscan
  const [nextCycleStartTimestamp, setNextCycleStartTimestamp] = useState();
  const [show, setShow] = useState(false); // enter next cycle modal
  const [showPreview, setShowPreview] = useState(false); // preview modal
  const [showFund, setShowFund] = useState(false); // set funding lock modal
  const [showDeposit, setShowDeposit] = useState(false); // set deposit modal
  const [showDisableFund, setShowDisableFund] = useState(false); // set Disable fund
  const [fundValue, setFundValue] = useState(); // fundValue must use ParseUnit before pass to PreviewNextCycle
  const [fundValuePreview, setFundValuePreview] = useState();
  const [timestampToLocalTime, setTimestampToLocalTime] = useState();
  const [usdcSigner, setUsdcSigner] = useState();
  const [lastTotalFundValue, setLastTotalFundValue] = useState();
  const [alertShow, setAlertShow] = useState(false);
  const [withdrawAmountPreview, setWithdrawAmountPreview] = useState(); // WithdrawAmount from PreviewNextCycle
  const [withdrawAmountBig, setWithdrawAmountBig] = useState();
  const [enterFundValue, setEnterFundValue] = useState(); // enter next cycle info of HT
  const [enterFundValueBig, setEnterFundValueBig] = useState(); // enter next cycle info of HT
  const [enterCycleIndex, setEnterCycleIndex] = useState();
  const [enterDepositLimit, setEnterDepositLimit] = useState();
  const [enterDepositLimitBig, setEnterDepositLimitBig] = useState();
  const [enterWithdrawAmount, setEnterWithdrawAmount] = useState(); // should enter withdrawAmount from PreviewNextCycle
  const [enterWithdrawAmountBig, setEnterWithdrawAmountBig] = useState(); // should enter withdrawAmount from PreviewNextCycle
  const [enterCycleStartTimestamp, setEnterCycleStartTimestamp] = useState(); // should enter start timestamp same with PreviewNextCycle
  const [enterFundingLockTimestamp, setEnterFundingLockTimestamp] = useState();
  const [enterCloseFund, setEnterCloseFund] = useState();
  const [enterCloseFundPreview, setEnterCloseFundPreview] = useState();
  const [enterCycleLocalTime, setEnterCycleLocalTime] = useState();
  const [enterLockLocalTime, setEnterLockLocalTime] = useState();
  const [depositTeaVault, setDepositTeaVault] = useState(); // deposit asset to TeaVault
  const [withdrawTeaVault, setWithdrawTeaVault] = useState(); // withdraw asset from TeaVault
  const [platformVault, setPlatformVault] = useState(); // fee config
  const [managerVault, setManagerVault] = useState();
  const [platformEntryFee, setPlatformEntryFee] = useState();
  const [managerEntryFee, setManagerEntryFee] = useState();
  const [platformExitFee, setPatformExitFee] = useState();
  const [managerExitFee, setManagerExitFee] = useState();
  const [platformPerformanceFee, setPlatformPerformanceFee] = useState();
  const [managerPerformanceFee, setManagerPerformanceFee] = useState();
  const [platformManagementFee, setPlatformManagementFee] = useState();
  const [managerManagementFee, setManagerManagementFee] = useState();
  const [teaVaultV2, setTeaVaultV2] = useState(); // fund config
  const [disableNFTChecks, setDisableNFTChecks] = useState();
  const [disableDepositing, setDisableDepositing] = useState();
  const [disableWithdrawing, setdDisableWithdrawing] = useState();
  const [disableCancelDepositing, setDisableCancelDepositing] = useState();
  const [disableCancelWithdrawing, setDisableCancelWithdrawing] = useState();
  const [cycleState, setCycleState] = useState();
  const [totalFundValue, setTotalFundValue] = useState();
  const [fundValueAfterRequests, setFundValueAfterRequests] = useState();
  const [requestedDeposits, setRequestedDeposits] = useState();
  const [convertedDeposits, setConvertedDeposits] = useState();
  const [requestedWithdrawals, setRequestedWithdrawals] = useState();
  const [convertedWithdrawals, setConvertedWithdrawals] = useState();

  const connectWallet = async () => {
    try {
      const web3Provider = await web3Modal.connect();
      const ethersProvider = new ethers.providers.Web3Provider(web3Provider);
      const accounts = await ethersProvider.listAccounts();
      const network = await ethersProvider.getNetwork();
      const signer = await ethersProvider.getSigner();
      const networkName = await network.name;
      const chainId = await network.chainId.toString();
      setWeb3ModalProvider(web3ModalProvider);
      setEthersProvider(ethersProvider);

      setSigner(signer);
      //   setAccount(account)
      if (accounts) setAccount(accounts[0]);
      if (chainId !== ChainId) {
        throw "Not correct network, please change.";
      }
      setChainId(chainId);
      setNetwork(network);
      setNetworkName(networkName);
    } catch (error) {
      console.log(error);
      alert(error);
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setContractSigner();
    setContractAddress();
  };

  // disconnect wallet
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.removeItem("walletconnect");
    refreshState();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);
  const notConnectContractError = async () => {
    if (contractSigner == undefined) {
      alert("Please choose contract address.");
      setError("Please choose contract address.");
      setAlertShow(false);
    }
  };

  const clearStateOfFund = async () => {
    // global state
    setCycleIndex("");
    setEnterCycleIndex(""); // set enter info
    setLockAsset("");
    setCycleStartTimestamp("");
    setFundingLockTimestamp("");
    setFundClosed("");
    setDepositLimit("");
    setLastTotalFundValue("");
    // enter next cycle
    setEnterCloseFund("");
    setEnterCycleIndex("");
    setEnterCycleLocalTime("");
    setEnterLockLocalTime("");
    setEnterCycleStartTimestamp("");
    setEnterFundValue("");
    setEnterFundingLockTimestamp("");
    setEnterFundValueBig("");
    setEnterWithdrawAmount("");
    setEnterWithdrawAmountBig("");
    setEnterDepositLimitBig("");
    setError("");
    setNewDepositLimit("");
    setWithdrawAmountBig("");
    setWithdrawAmountPreview("");
    // fee config
    setPlatformVault("");
    setManagerVault("");
    setPlatformEntryFee("");
    setManagerEntryFee("");
    setPatformExitFee("");
    setManagerExitFee("");
    setPlatformPerformanceFee("");
    setManagerPerformanceFee("");
    setPlatformManagementFee("");
    setManagerManagementFee("");
    // preview next cycle
    setFundValue("");
    setFundValuePreview("");
    setTimestampToLocalTime("");
    setNextCycleStartTimestamp("");
  };
  // OP contract

  const connectOPContract = async () => {
    // set OP contract
    console.log("start op");
    console.log("op contract address : ", opContractAddress);

    await clearStateOfFund();
    const contract = new ethers.Contract(
      opContractAddress,
      HighTableVault.abi,
      signer
    );

    setContractSigner(contract);
    const contractName = await contract.name();
    console.log("contract name : ", contractName);
    setContractAddress(opContractAddress);
    setContractName(contractName);
    const totalSupply = await contract.totalSupply();
    setTotalSupply(totalSupply);
    console.log("Total Supply : ", totalSupply);
    const symbol = await contract.symbol();
    console.log("symbol : ", symbol);

    setSymbol(symbol);
    // get asset contract from strategy contract
    const assetAddress = await contract.asset();
    console.log("assetAddress : ", assetAddress);
    console.log("signer : ", signer);
    const contractAsset = new ethers.Contract(assetAddress, USDC.abi, signer);
    // console.log("contractAsset ", contractAsset);

    const AssetName = await contractAsset.name();
    const AssetDecimals = await contractAsset.decimals();
    console.log("Asset Name ", AssetDecimals);
    setUSDCName(AssetName);
    console.log("Asset Decimals ", AssetDecimals);
    setUSDCDecimals(AssetDecimals);

    const fundConfig = await contract.fundConfig();
    const TeaVaultV2Address = fundConfig.teaVaultV2;
    setTeaVaultV2Address(TeaVaultV2Address);
    const tvLink = etherScanPrefix + "address/" + TeaVaultV2Address;
    setTeavaultLink(tvLink);
  };
  // OP plus contract
  const connectOPPlusContract = async () => {
    console.log("start op plus");
    console.log("opPlusContractAddress : ", opPlusContractAddress);

    await clearStateOfFund();
    // set OP plus contract
    const contract = new ethers.Contract(
      opPlusContractAddress,
      HighTableVault.abi,
      signer
    );

    setContractSigner(contract);
    const contractName = await contract.name();
    console.log("contract name : ", contractName);
    setContractAddress(opPlusContractAddress);
    setContractName(contractName);
    const totalSupply = await contract.totalSupply();
    setTotalSupply(totalSupply);
    const symbol = await contract.symbol();
    setSymbol(symbol);
    // get asset contract from strategy contract
    const assetAddress = await contract.asset();
    const contractAsset = new ethers.Contract(assetAddress, USDC.abi, signer);
    console.log("contractAsset ", contractAsset);

    const AssetName = await contractAsset.name();
    const AssetDecimals = await contractAsset.decimals();
    console.log("USDCName ", AssetDecimals);
    setUSDCName(AssetName);
    console.log("USDCDecimals ", AssetDecimals);
    setUSDCDecimals(AssetDecimals);

    const fundConfig = await contract.fundConfig();
    const TeaVaultV2Address = fundConfig.teaVaultV2;
    setTeaVaultV2Address(TeaVaultV2Address);
    const tvLink = etherScanPrefix + "address/" + TeaVaultV2Address;
    setTeavaultLink(tvLink);
  };
  // ETH staking contract
  const connectETHStakingContract = async () => {
    // set OP plus contract
    console.log("start eth staking");
    console.log("ethStakingContractAddress : ", ethStakingContractAddress);
    await clearStateOfFund();
    const contract = new ethers.Contract(
      ethStakingContractAddress,
      HighTableVaultETH.abi,
      signer
    );

    setContractSigner(contract);
    const contractName = await contract.name();
    console.log("contract name : ", contractName);
    setContractAddress(ethStakingContractAddress);
    setContractName(contractName);
    const totalSupply = await contract.totalSupply();
    setTotalSupply(totalSupply);
    const symbol = await contract.symbol();
    setSymbol(symbol);
    // get asset contract from strategy contract
    const assetAddress = await contract.asset();
    const contractAsset = new ethers.Contract(assetAddress, USDC.abi, signer);
    console.log("contractAsset ", contractAsset);

    const AssetName = await contractAsset.name();
    const AssetDecimals = await contractAsset.decimals();
    console.log("Asset Name ", AssetName);
    setUSDCName(AssetName);
    console.log("Asset Decimals ", AssetDecimals);
    setUSDCDecimals(AssetDecimals);

    const fundConfig = await contract.fundConfig();
    const TeaVaultV2Address = fundConfig.teaVaultV2;
    setTeaVaultV2Address(TeaVaultV2Address);
    const tvLink = etherScanPrefix + "address/" + TeaVaultV2Address;
    setTeavaultLink(tvLink);
    setIsEthStaking(true);
  };

  const getGlobalState = async () => {
    try {
      await notConnectContractError();
      const globalState = await contractSigner.globalState();
      let cycleIndex = globalState.cycleIndex;

      let cycleStartTimestamp = globalState.cycleStartTimestamp;
      let fundingLockTimestamp = globalState.fundingLockTimestamp;
      console.log("getGlobalState Usdc decimals : ", USDCDecimals);
      let depositLimit = (
        await FormatUnit(globalState.depositLimit, USDCDecimals)
      ).toString();
      console.log("depositLimit : ", depositLimit);
      let fundClosed = globalState.fundClosed;
      let lockedAssets = (
        await FormatUnit(globalState.lockedAssets, USDCDecimals)
      ).toString();

      setCycleIndex(cycleIndex);
      setEnterCycleIndex(cycleIndex); // set enter info

      setLockAsset(lockedAssets.toString());
      setCycleStartTimestamp(cycleStartTimestamp.toString());
      setFundingLockTimestamp(fundingLockTimestamp.toString());
      setFundClosed(fundClosed.toString());
      setDepositLimit(depositLimit.toString());
      console.log("cycle index : ");

      const lastIndex = cycleIndex - 1;
      console.log("last index ", lastIndex);
      const lastCycleState = await contractSigner.cycleState(lastIndex);
      const lastFundBigNumber = lastCycleState.totalFundValue;
      const lastFund = await FormatUnit(
        lastFundBigNumber.toString(),
        USDCDecimals
      );
      console.log("last Fund : ", lastFund);
      setLastTotalFundValue(lastFund);
    } catch (error) {
      console.log(error);

      setError(error);
    }
  };

  // get fund config
  const getFundConfig = async () => {
    const fundConfig = await contractSigner.fundConfig();
    const teaVaultV2 = fundConfig.teaVaultV2;
    const disableNFTChecks = fundConfig.disableNFTChecks;
    const disableDepositing = fundConfig.disableDepositing;
    const disableWithdrawing = fundConfig.disableWithdrawing;
    const disableCancelDepositing = fundConfig.disableCancelDepositing;
    const disableCancelWithdrawing = fundConfig.disableCancelWithdrawing;
    setTeaVaultV2(teaVaultV2);
    setDisableNFTChecks(disableNFTChecks.toString());
    setDisableDepositing(disableDepositing.toString());
    setdDisableWithdrawing(disableWithdrawing.toString());
    setDisableCancelDepositing(disableCancelDepositing.toString());
    setDisableCancelWithdrawing(disableCancelWithdrawing.toString());
  };

  // handle set new Funding Lock timestamp
  const handleSubmitNewFundLockTimestamp = (event) => {
    event.preventDefault();
    let timestamp = parseInt(newFundingLockTimestamp);
    setNewFundingLockTimestamp(timestamp);
    let local = HandleLocalTime(timestamp);
    setNewFundingLockLocalTime(local);
  };

  // setFundingLockTimeStamp
  const setNewFundLockTimestamp = async () => {
    try {
      console.log("timestamp after: ", typeof newFundingLockTimestamp);
      // call contract
      let tx = await contractSigner.setFundLockingTimestamp(
        newFundingLockTimestamp
      );
      console.log("tx : ", tx);
      setTxLink(etherScanPrefix + "tx/" + tx.hash);
    } catch (error) {
      let err = JSON.parse(JSON.stringify(error));
      console.log("err : ", err);
      console.log("err hash: ", err.error.data.originalError.data);
      alert(err);
      setError(error);
    }
  };

  // handle set new Deposit limit
  const handleSubmitNewDepositLimit = async (event) => {
    event.preventDefault();
    let inputLimit = ParseUnit(newDepositLimit, USDCDecimals).toString();
    setNewDepositLimit(inputLimit);
  };

  // set new Deposit limit
  const setNewLimitDeposit = async () => {
    try {
      // call contract

      let tx = await contractSigner.setDepositLimit(newDepositLimit);
      setTxLink(etherScanPrefix + "tx/" + tx.hash);
    } catch (error) {
      console.log(error);
      alert(error);
      setError(error);
    }
  };
  // handle Preview Next Cycle

  const HandleSubmitPreview = async (event) => {
    event.preventDefault();
    let value = ParseUnit(fundValue, USDCDecimals);
    console.log("HandleSubmitPreview value : ", value);
    setFundValuePreview(value.toString());

    setNextCycleStartTimestamp(nextCycleStartTimestamp.trim());

    // set Enter info
    setEnterFundValue(fundValue);
    setEnterCycleStartTimestamp(nextCycleStartTimestamp);
    let local = HandleLocalTime(nextCycleStartTimestamp);
    setTimestampToLocalTime(local);
  };

  const HandleLocalTime = (timestamp) => {
    let dateTime = new Date(timestamp * 1000);
    return dateTime.toLocaleString();
  };

  const PreviewNextCycle = async () => {
    try {
      await notConnectContractError();
      console.log("Preview enterFundValue :", enterFundValue);
      console.log("total supply : ");
      const enterFundValueBig = ParseUnit(enterFundValue, USDCDecimals);
      const globalState = await contractSigner.globalState();
      const curIndex = await globalState.cycleIndex;

      let aprCurrent;
      if (curIndex == 0 || curIndex == 1) {
        aprCurrent = 0;
      }
      // cycleIndex >= 2
      const lastCycleState = await contractSigner.cycleState(curIndex - 1);
      //   const lastTotalFundValue = lastCycleState.totalFundValue;
      const lastCycleFundValueAfterRequests =
        lastCycleState.fundValueAfterRequests;
      console.log(
        "lastCycleFundValueAfterRequests : ",
        lastCycleFundValueAfterRequests
      );
      //   const lastCycleFundValueAfterRequestsNum = ParseUnit(
      //     lastCycleFundValueAfterRequests,
      //     USDCDecimals
      //   );
      //   console.log(
      //     "lastCycleFundValueAfterRequestsNum : ",
      //     lastCycleFundValueAfterRequestsNum
      //   );
      console.log("preview next USDCDecimals :", USDCDecimals);
      //  get Next APR
      const newAPR =
        (enterFundValueBig
          .sub(lastCycleFundValueAfterRequests)
          .mul(100000000000)
          .div(lastCycleFundValueAfterRequests)
          .toNumber() /
          100000000000) *
        100 *
        52;
      console.log("preview next cycle newAPR: ", newAPR);
      const newAPRfixed = newAPR.toFixed(2);
      console.log("preview newAPRfixed : ", newAPRfixed);
      setNextAPR(newAPRfixed);
      // get current share price
      //   const fundValueAfter = lastCycleState.fundValueAfterRequests;
      const currentSharePrice =
        lastCycleFundValueAfterRequests
          .mul("1" + "0".repeat(18))
          .mul(100000000000)
          .div(totalSupply.mul("1" + "0".repeat(USDCDecimals)))
          .toNumber() / 100000000000;

      console.log("currentSharePrice Preview: ", currentSharePrice);
      setCurrentSharePrice(currentSharePrice);

      // get next share price
      const nextSharePrice =
        enterFundValueBig
          .mul("1" + "0".repeat(18))
          .mul(100000000000)
          .div(totalSupply.mul("1" + "0".repeat(USDCDecimals)))
          .toNumber() / 100000000000;
      console.log("nextSharePrice Preview: ", nextSharePrice);

      setNextSharePrice(nextSharePrice);

      if (
        enterFundValueBig
          .sub(lastCycleFundValueAfterRequests)
          .mul(10)
          .abs()
          .gt(lastCycleFundValueAfterRequests)
      ) {
        console.log("Over size");
        setAlertShow(true);
        // handleClosePreview();
      }

      let txPreview = await contractSigner.previewNextCycle(
        fundValuePreview,
        nextCycleStartTimestamp
      );
      let withdrawAmount = FormatUnit(
        txPreview.toString(),
        USDCDecimals
      ).toString();
      console.log("PreviewNextCycle withdrawAmount : ", withdrawAmount);
      setWithdrawAmountBig(txPreview.toString());
      setWithdrawAmountPreview(withdrawAmount);
      setEnterWithdrawAmount(withdrawAmount);
    } catch (error) {
      console.log("error : ", error);
      alert(error);
      setError(error);
    }
  };

  // close preview modal
  const handleClosePreview = () => setShowPreview(false);
  // open preview modal
  const handleShowPreview = () => setShowPreview(true);

  // close enter modal
  const handleClose = () => setShow(false);
  // open enter modal
  const handleShow = () => setShow(true);

  // close set Fund timestamp modal
  const handleCloseFund = () => setShowFund(false);
  // open enter modal
  const handleShowFund = () => setShowFund(true);

  // close set deposit limit modal
  const handleCloseDeposit = () => setShowDeposit(false);
  // open set deposit limit modal
  const handleShowDeposit = () => setShowDeposit(true);

  // close set disable fund modal
  const handleCloseDisableFund = () => setShowDisableFund(false);
  // open set disable fund modal
  const handleShowDisableFund = () => setShowDisableFund(true);

  // Handle Enter Next Cycle
  const HandleSubmitEnterInfo = async (event) => {
    event.preventDefault();

    const value = ParseUnit(enterFundValue, USDCDecimals).toString();
    console.log("HandleSubmitEnterInfo value : ", value);

    const valueBig = ParseUnit(enterFundValue, USDCDecimals);

    // get new share price
    const newSharePrice = valueBig / totalSupply;
    console.log("nextSharePrice : ", newSharePrice);
    // setNextSharePrice(newSharePrice);

    setEnterFundValueBig(value);
    let deposit = (await ParseUnit(enterDepositLimit, USDCDecimals)).toString();
    console.log("HandleSubmitEnterInfo deposit : ", deposit);

    setEnterDepositLimitBig(deposit);
    let withdraw = (
      await ParseUnit(enterWithdrawAmount, USDCDecimals)
    ).toString();
    console.log("HandleSubmitEnterInfo withdraw : ", withdraw);

    setEnterWithdrawAmountBig(withdraw);

    let startLocalTime = HandleLocalTime(enterCycleStartTimestamp);
    let lockLocalTime = HandleLocalTime(enterFundingLockTimestamp);

    setEnterCycleLocalTime(startLocalTime);
    setEnterLockLocalTime(lockLocalTime);

    // let inputBool = ConvertTrueBool(enterCloseFund);
    let inputBool = ConvertCloseFund(enterCloseFund);
    setEnterCloseFund(inputBool);
  };

  const EnterNextCycle = async () => {
    try {
      console.log(
        "enter info : ",
        enterCycleIndex,
        enterFundValueBig,
        enterDepositLimitBig,
        enterWithdrawAmountBig,
        enterCycleStartTimestamp,
        enterFundingLockTimestamp,
        enterCloseFund
      );

      console.log("isEthStaking : ", isEthStaking);
      if (isEthStaking == true) {
        let txEnter = await contractSigner.enterNextCycleETH(
          enterCycleIndex,
          enterFundValueBig,
          enterDepositLimitBig,
          enterWithdrawAmountBig,
          enterCycleStartTimestamp,
          enterFundingLockTimestamp,
          enterCloseFund
        );
        setTxLink(etherScanPrefix + "tx/" + txEnter.hash);
      } else {
        let txEnter = await contractSigner.enterNextCycle(
          enterCycleIndex,
          enterFundValueBig,
          enterDepositLimitBig,
          enterWithdrawAmountBig,
          enterCycleStartTimestamp,
          enterFundingLockTimestamp,
          enterCloseFund
        );
        setTxLink(etherScanPrefix + "tx/" + txEnter.hash);
      }
    } catch (error) {
      console.log(error);
      alert(error);
      setError(error);
    }
  };

  // Handle Deposit to TeaVault v2
  const HandleSubmitDepositToVault = (event) => {
    event.preventDefault();
    let depositAmount = ParseUnit(depositTeaVault, USDCDecimals).toString();
    setDepositTeaVault(depositAmount);
    console.log("deposit : ", depositTeaVault);
  };

  const DepositAmountToTeaVault = async () => {
    try {
      console.log("deposit amount : ", depositTeaVault);
      let txDeposit = await contractSigner.depositToVault(depositTeaVault);

      setTxLink(etherScanPrefix + "tx/" + txDeposit.hash);
    } catch (error) {
      console.log(error);
      alert(error);
      setError(error);
    }
  };
  // Handle Withdraw From TeaVault v2
  const HandleSubmitWithdrawFromVault = (event) => {
    event.preventDefault();
    let withdrawAmount = ParseUnit(withdrawTeaVault).toString();
    setWithdrawTeaVault(withdrawAmount);
  };

  const WithdrawAmountFromtTeaVault = async () => {
    try {
      console.log("Withdra amount : ", withdrawTeaVault);
      let tx = await contractSigner.withdrawFromVault(withdrawTeaVault);
      setTxLink(etherScanPrefix + "tx/" + tx.hash);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  // get feeConfig
  const getFeeConfig = async () => {
    try {
      console.log("getFeeConfig contractSigner ", contractSigner);
      if (contractSigner == undefined) {
        await notConnectContractError();
      }

      let tx = await contractSigner.feeConfig();

      setPlatformVault(tx.platformVault);
      setManagerVault(tx.managerVault);
      setPlatformEntryFee(tx.platformEntryFee);
      setManagerEntryFee(tx.managerEntryFee);
      setPatformExitFee(tx.platformExitFee);
      setManagerExitFee(tx.managerExitFee);
      setPlatformPerformanceFee(tx.platformPerformanceFee);
      setManagerPerformanceFee(tx.managerPerformanceFee);
      setPlatformManagementFee(tx.platformManagementFee);
      setManagerManagementFee(tx.managerManagementFee);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  // handle set Disable funding
  const HandleSubmitDiableFunding = (event) => {
    event.preventDefault();
    let inputDisableDeposit = ConvertDisableDP(disableDepositing);
    setDisableDepositing(inputDisableDeposit);
    let inputDiableWithdraw = ConvertDisableWT(disableWithdrawing);
    setdDisableWithdrawing(inputDiableWithdraw);
    let inputDisableCancelDeposit = ConvertDisableCancelDP(
      disableCancelDepositing
    );
    setDisableCancelDepositing(inputDisableCancelDeposit);
    let inputDiableCancelWithdraw = ConvertDisableCancelWT(
      disableCancelWithdrawing
    );
    setDisableCancelWithdrawing(inputDiableCancelWithdraw);
  };

  const setDisableFunding = async () => {
    try {
      let tx = await contractSigner.setDisableFunding(
        disableDepositing,
        disableWithdrawing,
        disableCancelDepositing,
        disableCancelWithdrawing
      );
      console.log("tx : ", tx);
      setTxLink(etherScanPrefix + "tx/" + tx.hash);
    } catch (error) {
      console.log(error);
      alert(error);
      setError(error);
    }
  };
  // cycle state
  const CheckCycleState = async () => {
    try {
      console.log("cycleState :", cycleState);
      const checkState = await contractSigner.cycleState(cycleState);
      console.log("check state : ", checkState);
      const totalFund = checkState.totalFundValue;
      const fundValueAfter = checkState.fundValueAfterRequests;
      const reqDeposit = checkState.requestedDeposits;
      const convDeposits = checkState.requestedDeposits;
      const reqWithdraw = checkState.requestedWithdrawals;
      const convWithdraw = checkState.convertedWithdrawals;

      setTotalFundValue(totalFund.toString());
      setFundValueAfterRequests(fundValueAfter.toString());
      setRequestedDeposits(reqDeposit.toString());
      setConvertedDeposits(convDeposits.toString());
      setRequestedWithdrawals(reqWithdraw.toString());
      setConvertedWithdrawals(convWithdraw.toString());
    } catch (error) {
      setError(error);
      console.log("error : ", error);
    }
  };

  //   const lastCycleSharePrice = async () => {
  //     try {
  //       console.log("lastCycleSharePrice cycle index : ", cycleIndex);
  //       console.log("lastCycleSharePrice total supply: ", totalSupply);
  //       const lastState = await contractSigner.cycleState(cycleIndex);
  //       const fundValueAfter = lastState.fundValueAfterRequests;
  //       const currentSharePrice = fundValueAfter / totalSupply;

  //       console.log("sharePrice : ", currentSharePrice);
  //       setCurrentSharePrice(currentSharePrice);
  //     } catch (error) {
  //       setError(error);
  //       console.log("error : ", error);
  //     }
  //   };

  return (
    <>
      {/* Nav bar */}
      <bt.Navbar bg="info" expand="lg">
        <bt.Container>
          <bt.Navbar.Brand href="#home">
            Teahouse Strategy Auditor
          </bt.Navbar.Brand>
          <bt.Navbar.Toggle aria-controls="basic-navbar-nav" />
          <bt.Navbar.Collapse id="basic-navbar-nav">
            <bt.Nav className="me-auto">
              <bt.Nav.Link href="#previewNextCyle">
                Preview Next Cycle
              </bt.Nav.Link>
              <bt.Nav.Link href="#enterNextCycle">Enter Next Cycle</bt.Nav.Link>
              <bt.NavDropdown title="Other Fund Option" id="basic-nav-dropdown">
                <bt.NavDropdown.Item href="#globalState">
                  Fund globlae state
                </bt.NavDropdown.Item>

                <bt.NavDropdown.Item href="#setFundLock">
                  Set FundingLock timestamp
                </bt.NavDropdown.Item>
                <bt.NavDropdown.Item href="#setDeposit">
                  Set Deposit limit
                </bt.NavDropdown.Item>
                <bt.NavDropdown.Item href="#setDisableFund">
                  Set Disable Fund
                </bt.NavDropdown.Item>
                <bt.NavDropdown.Item href="#fundConfig">
                  Fund Config
                </bt.NavDropdown.Item>
                <bt.NavDropdown.Item href="#feeConfig">
                  Fee Config
                </bt.NavDropdown.Item>

                <bt.NavDropdown.Divider />
              </bt.NavDropdown>
            </bt.Nav>
          </bt.Navbar.Collapse>
        </bt.Container>
      </bt.Navbar>
      <p></p>
      {/* connect wallet */}
      <bt.Container className="justify-content-md-center">
        <bt.Button variant="primary" onClick={connectWallet}>
          Connect Wallet
        </bt.Button>

        <bt.Button
          variant="danger"
          color="error"
          className="ms-3"
          onClick={disconnect}
        >
          Disconnect
        </bt.Button>
      </bt.Container>
      <bt.Container>
        <bt.Row className="mt-3">
          <bt.Col xs={2} md={6}>
            {account ? (
              <bt.Alert variant="success">
                <p>Network: {networkName}</p>
                <p>ChainID : {chainId}</p>
                <p>Address: {account}</p>
              </bt.Alert>
            ) : (
              <bt.Alert variant="danger">No connect network</bt.Alert>
            )}
          </bt.Col>

          <bt.Col xs={1} md={3}>
            <bt.Card>
              <bt.Card.Body>
                <bt.Card.Link href={teavaultLink} target="_blank">
                  TeaVault V2 fund value
                </bt.Card.Link>
              </bt.Card.Body>
            </bt.Card>
          </bt.Col>
          <bt.Col xs={1} md={3}>
            <bt.Card>
              <bt.Card.Body>
                <bt.Card.Link
                  href="https://www.epochconverter.com/"
                  target="_blank"
                >
                  Check Timestamp
                </bt.Card.Link>
              </bt.Card.Body>
            </bt.Card>
          </bt.Col>
        </bt.Row>
      </bt.Container>

      <bt.Container>
        {/* <p>contract address : {contractAddress}</p> */}
        <bt.Col sm={6}>
          <bt.DropdownButton
            id="dropdown-basic-button"
            title="Choice Contract Address"
            className="mb-3"
            variant="success"
          >
            <bt.Dropdown.Item onClick={connectOPContract}>OP</bt.Dropdown.Item>
            <bt.Dropdown.Item onClick={connectOPPlusContract}>
              OP plus
            </bt.Dropdown.Item>
            <bt.Dropdown.Item onClick={connectETHStakingContract}>
              ETH staking
            </bt.Dropdown.Item>
          </bt.DropdownButton>
          <p class="text-danger">Contract Address : </p>
          <p>{contractAddress}</p>
          <p class="text-primary">Contract Name : </p>
          <p>{contractName}</p>
        </bt.Col>
      </bt.Container>

      <p></p>
      <bt.Row>
        {/* Preview Next Cycle */}
        <bt.Col>
          <bt.Container id="previewNextCyle">
            <bt.Card bg="light">
              <bt.Card.Body>
                <bt.Card.Title className="text-success">
                  Preview Next Cycle
                </bt.Card.Title>
                <bt.Form onSubmit={HandleSubmitPreview}>
                  <bt.Form.Group className="mb-1">
                    <bt.Form.Label className="text-success"></bt.Form.Label>
                    <bt.Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="fund value"
                      onChange={(event) => setFundValue(event.target.value)}
                      value={fundValue || ""}
                    />

                    <bt.Form.Control
                      type="text"
                      placeholder="timestamp"
                      className="mb-4"
                      onChange={(event) =>
                        setNextCycleStartTimestamp(event.target.value)
                      }
                      value={nextCycleStartTimestamp || ""}
                    />

                    <bt.Button
                      type="submit"
                      variant="danger ms-3 mb-4"
                      onClick={() => {
                        handleShowPreview();
                        getGlobalState();
                        setAlertShow(false);
                      }}
                    >
                      Preview Next Cycle
                    </bt.Button>
                    <p>
                      Withdraw amount in {USDCName}: {withdrawAmountPreview}
                    </p>

                    <bt.Modal
                      size="lg"
                      show={showPreview}
                      onHide={handleClosePreview}
                      animation={false}
                    >
                      <bt.Modal.Header closeButton>
                        <bt.Modal.Title>
                          Preview Next Cycle to get Withdraw Amount
                        </bt.Modal.Title>
                      </bt.Modal.Header>
                      <bt.Modal.Body>
                        <p>Fund value: {fundValue}</p>
                        <p>Fund value in BigNumber: {fundValuePreview}</p>

                        <p>TimeStamp : {nextCycleStartTimestamp}</p>
                        <p>Local Time(月/日/年 時:分:秒):</p>
                        <p>{timestampToLocalTime}</p>
                      </bt.Modal.Body>
                      <bt.Modal
                        size="xl"
                        show={alertShow}
                        onHide={handleClosePreview}
                        animation={false}
                      >
                        <bt.Modal.Body className="bg-danger">
                          <p>不合理的 Fund Value</p>
                          <p>與上一Cycle的Fund Value 差異過大</p>
                          <p>請重新整理頁面, 輸入正確的Fund Value</p>
                        </bt.Modal.Body>
                      </bt.Modal>
                      <bt.Modal.Footer>
                        <bt.Button
                          variant="secondary"
                          onClick={handleClosePreview}
                        >
                          Cancel
                        </bt.Button>
                        <bt.Button variant="primary" onClick={PreviewNextCycle}>
                          Send transaction
                        </bt.Button>
                      </bt.Modal.Footer>
                      <p>Withdraw amount: {withdrawAmountPreview}</p>
                    </bt.Modal>
                  </bt.Form.Group>
                </bt.Form>
              </bt.Card.Body>
            </bt.Card>
          </bt.Container>
        </bt.Col>

        {/*Enter next cyle */}
        <bt.Col>
          <bt.Container id="enterNextCycle">
            <bt.Card bg="light">
              <bt.Card.Body>
                <bt.Card.Title className="text-danger mb-3">
                  Enter Next Cycle
                </bt.Card.Title>
                <bt.Form onSubmit={HandleSubmitEnterInfo}>
                  <bt.Form.Group className="mb-6">
                    {/* cycle index  */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="current cycle index"
                      onChange={(event) =>
                        setEnterCycleIndex(event.target.value)
                      }
                      value={enterCycleIndex || ""}
                    />

                    {/* fund value */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="fund value"
                      onChange={(event) =>
                        setEnterFundValue(event.target.value)
                      }
                      value={enterFundValue || ""}
                    />

                    {/* Deposit limit */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="deposit limit"
                      onChange={(event) =>
                        setEnterDepositLimit(event.target.value)
                      }
                      value={enterDepositLimit || ""}
                    />

                    {/* Withdraw amount */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="withdraw amount"
                      onChange={(event) =>
                        setEnterWithdrawAmount(event.target.value)
                      }
                      value={enterWithdrawAmount || ""}
                    />

                    {/* cycle start time */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="Next Cycle start timestamp"
                      onChange={(event) =>
                        setEnterCycleStartTimestamp(event.target.value)
                      }
                      value={enterCycleStartTimestamp || ""}
                    />

                    {/* funding lock timestamp  */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="Funding lock timestamp"
                      onChange={(event) =>
                        setEnterFundingLockTimestamp(event.target.value)
                      }
                      value={enterFundingLockTimestamp || ""}
                    />

                    {/* close fund */}
                    <bt.Form.Control
                      type="text"
                      className="mb-4"
                      placeholder="Type 'close fund' to close fund  "
                      onChange={(event) =>
                        setEnterCloseFund(event.target.value)
                      }
                      value={enterCloseFund || ""}
                    />
                    <bt.Button
                      type="submit"
                      variant="danger ms-3"
                      onClick={handleShow}
                    >
                      Enter Next Cycle
                    </bt.Button>

                    <bt.Modal
                      size="lg"
                      show={show}
                      onHide={handleClose}
                      animation={false}
                    >
                      <bt.Modal.Header closeButton>
                        <bt.Modal.Title>Enter Next Cycle</bt.Modal.Title>
                      </bt.Modal.Header>
                      <bt.Modal.Body>
                        <bt.Table striped bordered hover>
                          <tbody>
                            <tr class="table-info">
                              <td>Contract Name</td>
                              <td>{contractName}</td>
                            </tr>
                            <tr>
                              <td>Cycle Index</td>
                              <td>{enterCycleIndex}</td>
                            </tr>

                            <tr class="bg-danger className=text-white">
                              <td className="text-white">New APR</td>
                              <td className="text-white">{nextAPR}</td>
                            </tr>
                            <tr class="table-danger font-weight-bold">
                              <td>Next {symbol} Price</td>
                              <td>{nextSharePrice}</td>
                            </tr>
                            <tr>
                              <td>FundValue </td>
                              <td>{enterFundValue}</td>
                            </tr>
                            <tr>
                              <td>FundValue in BigNumber</td>
                              <td>{enterFundValueBig}</td>
                            </tr>
                            <tr>
                              <td>DepositLimit</td>
                              <td>{enterDepositLimit}</td>
                            </tr>
                            <tr>
                              <td>DepositLimit in BigNumber</td>
                              <td>{enterDepositLimitBig}</td>
                            </tr>
                            <tr>
                              <td>WithdrawAmount</td>
                              <td>{enterWithdrawAmount}</td>
                            </tr>
                            <tr>
                              <td>WithdrawAmount in BigNumber</td>
                              <td>{enterWithdrawAmountBig}</td>
                            </tr>
                            <tr>
                              <td>CycleStartTimestamp</td>
                              <td>{enterCycleStartTimestamp}</td>
                            </tr>
                            <tr>
                              <td>CycleStart Local Time(月/日/年)</td>
                              <td>{enterCycleLocalTime}</td>
                            </tr>
                            <tr>
                              <td>Funding LockTimestamp</td>
                              <td>{enterFundingLockTimestamp}</td>
                            </tr>
                            <tr>
                              <td>
                                Funding Lock Local Time (months / day / year
                                hr:min:sec)
                              </td>
                              <td>{enterLockLocalTime}</td>
                            </tr>
                            <tr class="bg-danger">
                              <td className="text-white">Close Fund</td>
                              <td className="text-white">
                                {JSON.stringify(enterCloseFund)}
                              </td>
                            </tr>
                          </tbody>
                        </bt.Table>
                      </bt.Modal.Body>
                      <bt.Modal.Footer>
                        <bt.Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </bt.Button>
                        <bt.Button variant="primary" onClick={EnterNextCycle}>
                          Send transaction
                        </bt.Button>
                      </bt.Modal.Footer>
                      <bt.Card.Link
                        href={txLink}
                        target="_blank"
                        className="mb-6"
                      >
                        Transaction on etherscan
                      </bt.Card.Link>
                    </bt.Modal>
                  </bt.Form.Group>
                  <bt.Card.Link href={txLink} target="_blank" className="mb-6">
                    Transaction on etherscan
                  </bt.Card.Link>
                </bt.Form>
              </bt.Card.Body>
            </bt.Card>
          </bt.Container>
        </bt.Col>
        <p></p>
        <bt.Row className="ms-6">
          <bt.Col className="ms-6">
            <bt.Container id="globalState">
              <bt.Button onClick={getGlobalState}>get global state </bt.Button>
              <p> depositLimit : {depositLimit} </p>
              <p> lockAssets: {lockAssets} </p>
              <p> cycleIndex : {cycleIndex} </p>
              <p> cycleStartTimestamp : {cycleStartTimestamp} </p>
              <p> fundingLockTimestamp : {fundingLockTimestamp} </p>
              <p> fundClosed : {fundClosed}</p>
            </bt.Container>
            {/* fee Config */}

            <bt.Container id="feeConfig">
              <bt.Button onClick={getFeeConfig}>get Fee Config </bt.Button>
              <p> platformVault : {platformVault} </p>
              <p> managerVault : {managerVault} </p>
              <p> platformEntryFee : {platformEntryFee} </p>
              <p> managerEntryFee : {managerEntryFee} </p>
              <p> platformExitFee : {platformExitFee} </p>
              <p> managerExitFee : {managerExitFee} </p>
              <p> platformPerformanceFee : {platformPerformanceFee}</p>
              <p> managerPerformanceFee : {managerPerformanceFee}</p>
              <p> platformManagementFee : {platformManagementFee}</p>
              <p> managerManagementFee : {managerManagementFee}</p>
            </bt.Container>

            {/* fund Config */}
            <bt.Container id="fundConfig">
              <bt.Button onClick={getFundConfig}>get Fund Config </bt.Button>
              <p> teaVaultV2 : {teaVaultV2} </p>
              <p> diable NFT checks : {disableNFTChecks} </p>
              <p> diable depositing : {disableDepositing} </p>
              <p> diable withdrawing : {disableWithdrawing} </p>
              <p> diable Cancle depositing : {disableCancelDepositing} </p>
              <p> diable Cancle withdrawing : {disableCancelWithdrawing} </p>
            </bt.Container>
            <bt.Container>
              {/* Deposit USDC */}
              <bt.Col>
                <bt.Form>
                  <bt.Form.Group className="mb-3" controlId="formBasicEmail">
                    <bt.Form.Label>Cycle state</bt.Form.Label>
                    <bt.Form.Control
                      type="text"
                      placeholder="type cycle index"
                      onChange={(event) => setCycleState(event.target.value)}
                      value={cycleState || ""}
                    />
                    <bt.Form.Text className="text-muted"></bt.Form.Text>

                    <bt.Button
                      variant="danger"
                      onClick={CheckCycleState}
                      className="mt-3"
                    >
                      Cycle state
                    </bt.Button>
                    <p>Total Fund Value : {totalFundValue}</p>
                    <p>Fund Value After Requests : {fundValueAfterRequests}</p>
                    <p>Requested Deposits : {requestedDeposits}</p>
                    <p>Converted Deposits : {convertedDeposits}</p>
                    <p>Requested Withdrawals : {requestedWithdrawals}</p>
                    <p>Converted Withdrawals : {convertedWithdrawals}</p>
                    {/* <bt.Card.Link href={txLink} target="_blank">
                      Transaction on etherscan
                    </bt.Card.Link> */}
                  </bt.Form.Group>
                </bt.Form>
              </bt.Col>
            </bt.Container>
          </bt.Col>

          {/* set new Funding lock timestamp */}
          <bt.Col>
            <bt.Container id="setFundLock" className="mt-4">
              <bt.Card bg="light">
                <bt.Card.Body>
                  <bt.Card.Title className="text-danger mb-3">
                    Set New FundingLock Timestamp
                  </bt.Card.Title>
                  <bt.Form onSubmit={handleSubmitNewFundLockTimestamp}>
                    <bt.Form.Group className="mb-3">
                      <bt.Form.Control
                        className="mb-3"
                        type="text"
                        placeholder="timestamp"
                        onChange={(event) =>
                          setNewFundingLockTimestamp(event.target.value)
                        }
                        value={newFundingLockTimestamp || ""}
                      />

                      <bt.Button type="submit" variant="primary ms-2 mb-4">
                        Submit
                      </bt.Button>

                      {/* modal */}
                      <bt.Button
                        variant="danger ms-3 mb-4"
                        onClick={handleShowFund}
                      >
                        Set FundLocking Timestamp
                      </bt.Button>

                      <bt.Modal
                        size="lg"
                        show={showFund}
                        onHide={handleCloseFund}
                        animation={false}
                      >
                        <bt.Modal.Header closeButton>
                          <bt.Modal.Title>
                            Set New FundingLock Timestamp
                          </bt.Modal.Title>
                        </bt.Modal.Header>
                        <bt.Modal.Body>
                          <p>Timestamp: {newFundingLockTimestamp}</p>
                        </bt.Modal.Body>
                        <bt.Modal.Footer>
                          <bt.Button
                            variant="secondary"
                            onClick={handleCloseFund}
                          >
                            Cancel
                          </bt.Button>
                          <bt.Button
                            variant="primary"
                            onClick={setNewFundLockTimestamp}
                          >
                            Send transaction
                          </bt.Button>
                        </bt.Modal.Footer>
                        <bt.Card.Link href={txLink} target="_blank">
                          Transaction on etherscan
                        </bt.Card.Link>
                      </bt.Modal>
                    </bt.Form.Group>
                  </bt.Form>
                </bt.Card.Body>
              </bt.Card>
            </bt.Container>

            {/* set new DepositLimit */}
            <bt.Col>
              <bt.Container id="setDeposit" className="mt-4">
                <bt.Card bg="light">
                  <bt.Card.Body>
                    <bt.Card.Title className="text-danger mb-3">
                      Set Deposit Limit
                    </bt.Card.Title>
                    <bt.Form onSubmit={handleSubmitNewDepositLimit}>
                      <bt.Form.Group className="mb-3">
                        <bt.Form.Control
                          className="mb-3"
                          type="text"
                          placeholder="deposit amount (USDC)"
                          onChange={(event) =>
                            setNewDepositLimit(event.target.value)
                          }
                          value={newDepositLimit || ""}
                        />
                        <bt.Button type="submit" variant="primary">
                          Submit
                        </bt.Button>
                        <bt.Button
                          variant="danger ms-3"
                          onClick={handleShowDeposit}
                        >
                          Set Deposit Limit
                        </bt.Button>{" "}
                        {/* modal */}
                        <bt.Modal
                          size="lg"
                          show={showDeposit}
                          onHide={handleCloseDeposit}
                          animation={false}
                        >
                          <bt.Modal.Header closeButton>
                            <bt.Modal.Title>
                              Set New Deposit Limit
                            </bt.Modal.Title>
                          </bt.Modal.Header>
                          <bt.Modal.Body>
                            <p>Deposit Limit (BigNumber): {newDepositLimit}</p>
                          </bt.Modal.Body>
                          <bt.Modal.Footer>
                            <bt.Button
                              variant="secondary"
                              onClick={handleCloseDeposit}
                            >
                              Cancel
                            </bt.Button>
                            <bt.Button
                              variant="primary"
                              onClick={setNewLimitDeposit}
                            >
                              Send transaction
                            </bt.Button>
                          </bt.Modal.Footer>
                          <bt.Card.Link href={txLink} target="_blank">
                            Transaction on etherscan
                          </bt.Card.Link>
                        </bt.Modal>
                        <p></p>
                        <bt.Card.Link href={txLink} target="_blank">
                          Transaction on etherscan
                        </bt.Card.Link>
                      </bt.Form.Group>
                    </bt.Form>
                  </bt.Card.Body>
                </bt.Card>
              </bt.Container>
              {/* Disable funding must use Auditor address */}
              <bt.Col>
                <bt.Container id="setDisableFund" className="mt-4">
                  <bt.Card bg="light">
                    <bt.Card.Body>
                      <bt.Card.Title className="text-danger mb-3">
                        Set Disable Fund
                      </bt.Card.Title>
                      <bt.Form onSubmit={HandleSubmitDiableFunding}>
                        <bt.Form.Group
                          className="mb-3"
                          controlId="formBasicEmail"
                        >
                          <bt.Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="disable depositing or not"
                            onChange={(event) =>
                              setDisableDepositing(event.target.value)
                            }
                            value={disableDepositing || ""}
                          />
                          <bt.Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="disable withdrawing or not"
                            onChange={(event) =>
                              setdDisableWithdrawing(event.target.value)
                            }
                            value={disableWithdrawing || ""}
                          />
                          <bt.Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="disable cancel depositing or not"
                            onChange={(event) =>
                              setDisableCancelDepositing(event.target.value)
                            }
                            value={disableCancelDepositing || ""}
                          />
                          <bt.Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="disable cancel withdrawing or not"
                            onChange={(event) =>
                              setDisableCancelWithdrawing(event.target.value)
                            }
                            value={disableCancelWithdrawing || ""}
                          />

                          <bt.Button type="submit" variant="primary">
                            Submit
                          </bt.Button>
                          <bt.Button
                            variant="danger ms-3"
                            onClick={handleShowDisableFund}
                          >
                            Set Disable Funding
                          </bt.Button>

                          {/* modal */}
                          <bt.Modal
                            size="lg"
                            show={showDisableFund}
                            onHide={handleCloseDisableFund}
                            animation={false}
                          >
                            <bt.Modal.Header closeButton>
                              <bt.Modal.Title>Set Disable Fund</bt.Modal.Title>
                            </bt.Modal.Header>
                            <bt.Modal.Body>
                              <p>
                                Disable depositing:{" "}
                                {JSON.stringify(disableDepositing)}
                              </p>
                              <p>
                                Disable withdrawing:{" "}
                                {JSON.stringify(disableWithdrawing)}
                              </p>
                              <p>
                                Disable cancel depositing:{" "}
                                {JSON.stringify(disableCancelDepositing)}
                              </p>
                              <p>
                                Disable cancel withdrawing:{" "}
                                {JSON.stringify(disableCancelWithdrawing)}
                              </p>
                            </bt.Modal.Body>
                            <bt.Modal.Footer>
                              <bt.Button
                                variant="secondary"
                                onClick={handleCloseDisableFund}
                              >
                                Cancel
                              </bt.Button>
                              <bt.Button
                                variant="primary"
                                onClick={setDisableFunding}
                              >
                                Send transaction
                              </bt.Button>
                            </bt.Modal.Footer>
                            <bt.Card.Link href={txLink} target="_blank">
                              Transaction on etherscan
                            </bt.Card.Link>
                          </bt.Modal>
                          <bt.Card.Link href={txLink} target="_blank">
                            Transaction on etherscan
                          </bt.Card.Link>
                        </bt.Form.Group>
                      </bt.Form>
                    </bt.Card.Body>
                  </bt.Card>
                </bt.Container>
              </bt.Col>
            </bt.Col>
          </bt.Col>
        </bt.Row>

        {/* FundLockingTimeStamp must use Auditor address */}
        <bt.Col>{/* DepositLimit must use Auditor address */}</bt.Col>
      </bt.Row>
    </>
  );
}
export default App;
